import * as yup from 'yup';

export const otpValidationSchema = yup.object({
    otpCode: yup
        .string('Enter Verification Code')
        .required('Verification Code is required')
        //.max(6, 'OTP should be 6 digits')
        //  .matches(/^[0-9]{6}$/, 'OTP should be 6 digits'),
        .matches(/^[0-9]{6}$/, 'Please enter valid verification code')
});
