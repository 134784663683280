import React from 'react'
import { Typography, Link, Grid, makeStyles } from '@material-ui/core';

const fontStyle = { color: 'white', fontSize: '13px' };

const useStyles = makeStyles((theme) => ({
    dinersPrivacy: {
        color: 'white', fontSize: '13px'
    },
    version: {
        justifyContent: "flex-end",
        alignItems: "flex-end",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "flex-start",
            alignItems: "flex-start"
        }
    }

}))

const Footer = () => {
    const classes = useStyles();
    return (
        <Grid container >
            <Grid item xs={12} md={2}>
                <Link href="https://www.dinersclub.com" target="_blank" align="left" ><Typography className={classes.dinersPrivacy}>www.dinersclub.com </Typography></Link>
            </Grid>
            <Grid item xs={12} md={3}>
                <Link href="https://www.dinersclub.com/privacy-policy/" target="_blank" align="left"><Typography className={classes.dinersPrivacy}>Privacy</Typography></Link>
            </Grid>
            <Grid container xs={12} md={7} className={classes.version}>
                <Grid item >
                    <Typography style={fontStyle}>Version: 0.20 | Diners Club International Ltd. @2022</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Footer;
