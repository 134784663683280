import React, { useReducer } from "react";
import AppContext from "./appContext";
import AppReducer from "./appReducer";

const initialState = {
  disputeData: {},
};
const AppState = (props) => {

  const [state, dispatch] = useReducer(AppReducer, initialState);

  const setDisputeData = (value) => {
    dispatch({
      type: "SET_DISPUETEDATA",
      payload: value,
    });
  }

  return (
    <AppContext.Provider
      value={{
        state,
        disputeData: state.disputeData,
        setDisputeData
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
}
export default AppState;

