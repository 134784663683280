import * as yup from 'yup';

export const validationSchema = yup.object({
    firstName: yup
        .string('Enter First Name')
        .required('First Name is required')
        .max(32, 'First Name should not exceed 32 characters')
        .matches(/^[A-Za-z ]*$/, 'Please enter valid First Name'),

    lastName: yup
        .string('Enter Last Name')
        .required('Last Name is required')
        .max(32, 'Last Name should not exceed 32 characters')
        .matches(/^[A-Za-z ]*$/, 'Please enter valid Last Name'),

    countryCode: yup
        .string('Enter Country Code')
        .required('Country Code is required')
        .not(['1'])
        .max(5, 'Country Code should not exceed 5 digits'),

    mobileNum: yup
        .string('Enter Mobile Number')
        .required("Mobile Number is required")
        .transform((currentValue) => currentValue.replace(/[-\s]/g, ""))
        .matches(/^\d+$/, 'Please enter valid Mobile Number')
        .min(10, "Mobile Number should be 10 digits")
        .max(10, "Mobile Number should not exceed 10 digits"),

    emailID: yup
        .string()
        .email('Please enter valid Email ID')
        .required("Email Id is required")
        .min(10, "Must be more than 10 characters")
        .max(320, "E-mail Id should not exceed 320 characters"),

    bankCode: yup
        .string('Enter Bank Name')
        .required('Bank Name is required')
        .max(4, 'Bank Name should not exceed 4 digits'),

    cardNum: yup
        .string('Enter Card Number')
        .required('Card Number is required')
        .matches(/^[0-9]{14,}$/,'Invalid card number')
        .test({
            name: 'validating card using Luhn Algorithm',
            exclusive: false,
            params: {},
            message: 'Invalid card number',
            test: function (value) {
                // Accept only numbers
                if (/[^0-9-\b]+/.test(value)) {
                    return false;
                }
                // The Luhn Algorithm. It's so pretty.
                let nCheck = 0, bEven = false;
                value = value.replace(/\D/g, "");
                for (var n = value.length - 1; n >= 0; n--) {
                    var cDigit = value.charAt(n),
                        nDigit = parseInt(cDigit, 10);
                    if (bEven && (nDigit *= 2) > 9) {
                        nDigit -= 9;
                    }
                        nCheck += nDigit;
                        bEven = !bEven;
                }
                return (nCheck !== 0) && (nCheck % 10) === 0;
            }
        }),
    acceptTerms: yup
        .bool().oneOf([true], 'Acceptance is required')
        .required('Card Number is required'),
});




