import React from 'react';
import { Box, Typography } from '@material-ui/core';
import CircleIcon from '@mui/icons-material/Circle';

const Introduction = (props) => {

   return (
      <>
         <Typography variant="body" component='div' style={{ fontSize: 13 }}>We respect the value of privacy of any Information you provide on the
        Online Dispute Resolution Portal available at <a href="https://www.dinersclub.com/">DinersClub.com</a> to Diners Club International Limited
             (“<Box fontWeight='fontWeightMedium' display='inline'>Diners Club</Box>”) and/or its affiliates and partners.
                     Diners Club is fully committed to respect and protect your privacy through its compliance with this privacy policy
                     (“<Box fontWeight='fontWeightMedium' display='inline'>Privacy Policy</Box>”).
                     The Privacy Policy applies only to the Online Dispute Resolution Portal.</Typography>
         <p />
         <Typography variant="body" component='div' style={{ fontSize: 13 }}>The terms which are not specifically defined under this Privacy Policy, but are used as
         capitalised terms and defined terms, shall have the same meaning as ascribed to them under the Online Dispute Resolution Portal terms of use, available below, and
         are inserted here by reference.</Typography>
         <p />
         <Typography variant="body" component='div' style={{ fontSize: 13 }}>This Privacy Policy describes:</Typography>
         <Typography variant="body" component='div' style={{ fontSize: 13 }}><CircleIcon style={{ fontSize: "0.5rem" }} /> Statement of Diners Club’s practices and policies
         in relation to your Data/Information (as defined below);</Typography>
         <Typography variant="body" component='div' style={{ fontSize: 13 }}><CircleIcon style={{ fontSize: "0.5rem" }} /> The types of Data/Information that we may collect
         from you, when you access or use the Online Dispute Resolution Portal;</Typography>
         <Typography variant="body" component='div' style={{ fontSize: 13 }}><CircleIcon style={{ fontSize: "0.5rem" }} /> To provide Information regarding purposes of and
         our practices regarding collecting, using, maintaining, processing, protecting and disclosing the Data/Information; and</Typography>
         <Typography variant="body" component='div' style={{ fontSize: 13 }}>
            <CircleIcon style={{ fontSize: "0.5rem" }} /> Security practices and procedures for protecting privacy of Data/Information.</Typography>
         <p />
         <Typography variant="body" component='div' style={{ fontSize: 13 }}>This Privacy Policy applies only to Information we collect on our Online Dispute Resolution
         Portal.</Typography>
         <p />
         <Typography variant="body" component='div' style={{ fontSize: 13 }}>Please read this Privacy Policy carefully to understand our policies and practices regarding
         your Data/Information and how we will treat it. You should only use the Online Dispute Resolution Portal if you have provided an explicit consent by clicking the
         acceptance of terms and conditions checkbox within the Online Dispute Resolution Portal evidencing your agreement to the practices
         described in this Privacy Policy and the terms and conditions as mentioned under the Online Dispute Resolution Portal Terms of Use (“ToU”). You also expressly consent
         to our use and disclosure of your Information (as defined below) in any manner as described in this Privacy Policy.</Typography>
      </>
   )

}
export default Introduction;
