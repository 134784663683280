import React, { useState } from 'react';
import { Grid, Card, Typography, TextField, Box, Button, makeStyles } from '@material-ui/core';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
      withRouter, Link
} from "react-router-dom";
import DialogPleaseWait from '../dialogs/DialogPleaseWait';
import DialogSystemDown from '../dialogs/DialogSystemDown';
import SessionExpiredPopup from "../dialogs/SessionExpiredPopup";
import DialogInvalidDetail from '../dialogs/DialogInvalidDetail';
import useWarning from '../useWarning';
import { useDispatch } from 'react-redux';
import {

      remove,

} from '../services/SessionService';
import {StatusEnquiryHelper} from "./StatusEnquiryHelper";

const useStyles = makeStyles((theme) => ({
      trackDisputeBox: {
            "background-color": "#e0dfdf",
            width: "700px",
            height: "100px",
            border: "rgb(187, 186, 186)",
            "border-style": "solid",
            [theme.breakpoints.down("sm")]: {
                  width: "420px",
                  height: "200px",
            },
            [theme.breakpoints.down("xs")]: {
                  width: "260px",
                  height: "200px",
            },

      },
      refTextField: {
            width: "150px",

      },
      buttons: {
            justifyContent: "center",
            alignItems: "center",
            [theme.breakpoints.down("sm")]: {
                  justifyContent: "flex-start",
                  alignItems: "flex-start",

            },
            [theme.breakpoints.down("xs")]: {
                  justifyContent: "center",
                  alignItems: "center",

            }
      }
}))

const validationSchema = yup.object({
      complaintId: yup
            .string('Enter Complaint ID')
            .required('Complaint ID is required')
            .matches(/^\d{1,12}?$/, 'Please enter valid Complaint ID')
            .max(12, "Complaint ID should not exceed 12 digits")

});
const DisputeStatusEnquiry = () => {

      const { checkComplaintStatus } = StatusEnquiryHelper();
      const classes = useStyles();
      const dispatch = useDispatch();

      React.useEffect(() => {
            return () => {
                  remove();
                  dispatch({ type: 'REMOVE_SESSION' });
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      const [Prompt, setDirty, setPristine] = useWarning();
      const [plsWait, setPlsWait] = useState(false);
      const [isSysDown, setIsSysDown] = useState(false);
      const [openSessionExpired, setOpenSessionExpired] = useState(false);
      const [openInvalidDetail, setOpenInvalidDetail] = React.useState(false);


      const openPlsWait = () => setPlsWait(true);
      const closePlsWait = () => setTimeout(() => setPlsWait(false), 2000);
      const openSysDown = () => setTimeout(() => setIsSysDown(true), 2000);
      const openSessionExpiredDialog = () => setTimeout(() => setOpenSessionExpired(true), 2000);
      const closeSysDown = () => setIsSysDown(false);
      const openInvDetail = () => setTimeout(() => setOpenInvalidDetail(true), 2000);
      const closeInvDetail = () => setOpenInvalidDetail(false);

      const formik = useFormik({
            initialValues: {
                  complaintId: ""

            },
            validationSchema: validationSchema,

            onSubmit: (values) => {
                  setPristine();
                  const complaintId = values.complaintId;
                  console.log('Status enquiry ID' + complaintId);
                  checkComplaintStatus({complaintId, openPlsWait, closePlsWait,
                      openInvDetail, openSessionExpiredDialog, openSysDown});
            },
      });

      function handleOnchange(e) {
            setDirty();
            formik.handleChange(e);

      }

      return (
            <div>
                  <Card className="homePageCard">
                        {Prompt}
                        <form id="trackDisputeForm" onSubmit={formik.handleSubmit} onReset={formik.resetForm}>
                              <p></p>
                              <Box borderColor="primary.main"
                                    paddingBottom={2} paddingTop={2} paddingLeft={6}>
                                    <Grid container>
                                          <Grid item >
                                                <Typography className="tranHeader">
                                                      COMPLAINT STATUS ENQUIRY</Typography>
                                          </Grid>
                                    </Grid>
                                    <hr className="raiseDisputehr"></hr>
                                    <p style={{ height: 20 }}></p>
                                    <Box className={classes.trackDisputeBox}>
                                          <Grid container spacing={1} className="trackDisputeGrid">
                                                <Grid item >
                                                      <Typography className="trackDisputeLabel">Please enter your Diners Club International Reference Number :</Typography>
                                                </Grid>
                                                <Grid item style={{ paddingLeft: '25px', paddingTop: '6px' }}>
                                                      <TextField id="complaintId" name="complaintId"
                                                            type="text" size="small" className={classes.refTextField}
                                                            value={formik.values.complaintId}
                                                            onChange={handleOnchange}
                                                            error={formik.touched.complaintId && Boolean(formik.errors.complaintId)}
                                                            helperText={formik.touched.complaintId && formik.errors.complaintId} />
                                                </Grid>
                                          </Grid>
                                    </Box>
                                    <p style={{ height: 50 }}></p>
                                    <Grid container xs={12} className={classes.buttons} >
                                          <div className="container">
                                                <div className="row">
                                                      <Button variant="contained" className="raiseDisputeButton" type="submit">
                                                            Submit
                                          </Button>
                                          &nbsp;&nbsp;
                                          <Button variant="contained" className="raiseDisputeButton" component={Link} to="/">
                                                            Cancel
                                          </Button>
                                                </div>
                                          </div>
                                    </Grid>
                              </Box>
                        </form>
                        <DialogInvalidDetail open={openInvalidDetail} close={closeInvDetail} />
                        <DialogPleaseWait open={plsWait} />
                        <DialogSystemDown open={isSysDown} close={closeSysDown} />
                        <SessionExpiredPopup open={openSessionExpired} />
                  </Card>
            </div>

      );
}
export default withRouter(DisputeStatusEnquiry);
