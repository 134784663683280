import React from 'react';
import { Box, Typography } from '@material-ui/core';
import CircleIcon from '@mui/icons-material/Circle';
import Introduction from './Introduction';
import TermsOfUse from './TermsOfUse';

const PrivacyPolicy = (props) => {
    return (
        <>
            <Introduction />
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>1.	DEFINITIONS</Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}><CircleIcon style={{ fontSize: "0.5rem" }} /> “<Box fontWeight='fontWeightMedium'
                display='inline'>Information</Box>” the Non-Personal Information (as defined below) and Personal Information (as defined below)
             is collectively referred to as “Information”.</Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}><CircleIcon style={{ fontSize: "0.5rem" }} /> “<Box fontWeight='fontWeightMedium'
                display='inline'>Non-personal Information</Box>” refers to any non-personal information collected from you pursuant to your access/use of the Online Dispute
                Resolution Portal, including but not limited to information related to card transaction for which you are filing/filed a complaint including but not limited to
                transaction amount, transaction currency, transaction date, type of transaction, merchant name, in an ‘as is’ manner.</Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}><CircleIcon style={{ fontSize: "0.5rem" }} /> “<Box fontWeight='fontWeightMedium'
                display='inline'>Personal Information</Box>” means any information that relates to you, which, either directly
             or indirectly, in combination with other information available or likely to be available, is capable of identifying you. Personal Information includes
              but is not limited to your name, your Diners Club card details, mobile number, email address, etc.</Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}><CircleIcon style={{ fontSize: "0.5rem" }} /> “<Box fontWeight='fontWeightMedium'
                display='inline'>Third Party</Box>” refers to any person or entity other than you or us.</Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>2.	THE INFORMATION WE COLLECT AND HOW WE USE IT </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}>We collect Information directly from you when you provide it to us.
            There is no other mechanism through which Diners Club collects Information from you.</Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}>The Information which we may collect can be divided into the following
            broad categories: </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}><Box fontWeight='fontWeightMedium' display='inline'>A.	Personal Information
             </Box> </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 30 }}><CircleIcon style={{ fontSize: "0.5rem" }} /><Box fontWeight='fontWeightMedium'
                display='inline'> Information you provide to us: </Box> The Information we collect on or through our Online Dispute Resolution Portal may include your full name,
             email address, postal code, mobile phone number, your Diners Club card details, transaction details for which a complaint is being raised by you on the Online Dispute
             Resolution Portal, and any other Personal Information you may provide while lodging your complaint on the Online Dispute Resolution Portal.</Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 30 }}><CircleIcon style={{ fontSize: "0.5rem" }} /><Box fontWeight='fontWeightMedium'
                display='inline'> Personal Information retrieved automatically. </Box> </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 40 }}>Diners Club being a card system network provider may have access or retrieve
            additional Personal Information that may not have been provided by you to Diners Club directly through the Online Dispute Resolution Portal. </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}><Box fontWeight='fontWeightMedium' display='inline'>B.	</Box>Diners Club may
            collect Non-personal Information from you which may include any Information in relation to your Diners Club card transaction for which you have/may file a complaint
             on the Online Dispute Resolution Portal.  </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}><Box fontWeight='fontWeightMedium' display='inline'>C.	Anonymous or De-Identified
             Data </Box> </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 30 }}>We may anonymize and/or de-identify Information collected from you.</Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>3.	HOW WE USE AND PROCESS THE DATA WE COLLECT</Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}>We undertake to use and process your Information with integrity, accountability,
            fairness and transparency. We also undertake to use our best efforts to protect the Information from unlawful processing.</Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}>We use and process the Information we collect from and about you for a variety
             of purposes, including for: </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}><CircleIcon style={{ fontSize: "0.5rem" }} /> Redressal of your dispute or
            grievance in relation to your Diners Club card.</Typography>
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}><CircleIcon style={{ fontSize: "0.5rem" }} /> Process and respond to your
            complaints and queries raised on the Online Dispute Resolution Portal.</Typography>
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}><CircleIcon style={{ fontSize: "0.5rem" }} /> Send you communications in relation
             to the complaint/dispute/grievance registered by you on the Online Dispute Resolution Portal.</Typography>
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}><CircleIcon style={{ fontSize: "0.5rem" }} /> Provide you with customer
            support. </Typography>
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}><CircleIcon style={{ fontSize: "0.5rem" }} /> For any other purpose with your
             consent.</Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}>The primary basis for use and processing of the Information is to redress the
            complaint/grievance/dispute registered by you on the Online Dispute Resolution Portal. </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}>We will hold your Information for no longer than necessary and only till the time
            the purposes for which it was collected and consented by you are achieved, unless required to be held for longer as any applicable law or where Diners Club is under
              a legal obligation to do so.  </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>4.	HOW WE SHARE THE DATA WE COLLECT </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}>We may disclose the Information we collect, or you provide, as described in this
             Privacy Policy, in the following ways: </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}><Box fontWeight='fontWeightMedium' display='inline'>General Information Disclosures
             </Box> </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}><CircleIcon style={{ fontSize: "0.5rem" }} /> To our subsidiaries and affiliates.
             </Typography>
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}><CircleIcon style={{ fontSize: "0.5rem" }} /> To the issuing bank, who has
            issued the Diners Club card to you.  </Typography>
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}><CircleIcon style={{ fontSize: "0.5rem" }} /> To fulfil the purpose for which
             you provided it.   </Typography>
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}><CircleIcon style={{ fontSize: "0.5rem" }} /> For any other purpose disclosed
             by us when you provided the Information. </Typography>
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}><CircleIcon style={{ fontSize: "0.5rem" }} /> <Box fontWeight='fontWeightMedium'
                display='inline'>As required by Law.</Box> We may share your Information when we believe in good faith that such sharing is reasonably necessary in order to
             investigate, prevent, or take action regarding possible illegal activities or to comply with legal process.
             We may also share your Information to investigate and address threats or potential threats to the physical safety of any person, to investigate and address
             violations of this Privacy Policy or the Online Dispute Resolution Portal ToU. This may involve sharing of your Information with law enforcement, government agencies,
              courts, and/or other organizations on account of legal request such as subpoena, court order or government demand to comply with the law. </Typography>
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}><CircleIcon style={{ fontSize: "0.5rem" }} /> To enforce or apply the Online
            Dispute Resolution Portal ToU. </Typography>
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}><CircleIcon style={{ fontSize: "0.5rem" }} /> If we believe disclosure is necessary
             or appropriate to protect the rights, property, or safety of Diners Club. This includes exchanging Information with other companies and organizations for the purposes
              of fraud protection. </Typography>
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}><CircleIcon style={{ fontSize: "0.5rem" }} /> <Box fontWeight='fontWeightMedium'
                display='inline'>Consent.</Box> We may share your Information in any other circumstances where we have your consent.</Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>5. SECURITY: HOW WE PROTECT YOUR INFORMATION </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}>We have implemented appropriate physical, electronic, and managerial procedures
            to safeguard and help prevent unauthorized access to your Information and to maintain data security. These safeguards take into account the sensitivity of the
            Information that we collect, process and store. Technological safeguards that we implement to secure your
            Information includes firewall protection,
            end-to-end encryption of your Information while in transit, all the server connections made through the Online Dispute Resolution Portal are HTTPS secured.
            At an organisational level we only allow specific and authorised personnel of the Diners Club, who are pre-authorised, to access your Information only on a need
              to know basis.</Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}>However, no method of transmission over the internet or via mobile device, or
            method of electronic storage, is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your Information, we cannot guarantee its
            absolute security and thereby usage of the Online Dispute Resolution Portal in a manner that is inconsistent with the Online Dispute Resolution Portal ToU and this
            Privacy Policy is not recommended. </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}>We assume no liability or responsibility for disclosure of your Information due
            to errors in transmission, unauthorized third-party access, or other causes beyond our control. You play an important role in keeping your Personal Information secure.
            You should not share your Information with anyone. If we receive instructions using your Information, we will consider that you have authorized such instructions.
            Further, notwithstanding anything contained in this Privacy Policy or elsewhere, we shall not be held responsible for any loss, damage or misuse of your Information,
            if such loss, damage or misuse is attributable to any event that is beyond our reasonable control or is directly attributable to you. </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>6. CHILDREN UNDER 18</Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}>The use of the Online Dispute Resolution Portal is not intended for users
            under the age of 18. We do not knowingly collect any Information from children under 18 or market to or solicit Information from anyone under the age of 18. If we
            become aware that a person submitting Information is under 18, we will delete such Information and any related Information as soon as possible. If you believe we
            might have any Information from or about a child under 18, please contact us at <a href="mailto:Privacy@discover.com">Privacy@discover.com</a>. </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>7. COMPLIANCE WITH DATA PROTECTION REGULATIONS </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}>The Information we obtain from or about you may be processed and stored in India
            and servers within the United States of America, which may provide for different data protection rules than the country in which you reside. We comply with generally
            accepted industry standard regulations regarding the collection, use, and retention of data. By using the Online Dispute Resolution Portal, you consent to the
            collection, transfer, use, storage and disclosure of your Information as described in this Privacy Policy. If you have any questions relating to your Information,
            please contact us at <a href="mailto:Privacy@discover.com">Privacy@discover.com</a>. </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>8. CHANGES TO THIS PRIVACY POLICY</Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 15 }}>We reserve the right to amend and update this Privacy Policy from time to time
            to reflect changes in the law, our data collection and use practices, the features of our Online Dispute Resolution Portal. Please check this page periodically for
            any such changes. Use of Information we collect is subject to the Privacy Policy in effect at the time such Information is used. This Privacy Policy was last updated
            on March 21, 2023.</Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>9. CONTACT US</Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13, marginLeft: 21 }}><CircleIcon style={{ fontSize: "0.5rem" }} /> If you have any queries relating to
            the processing/usage of Information provided by you or Privacy Policy, you may contact us at <a href="mailto:Privacy@discover.com">Privacy@discover.com</a>.
            </Typography>
            <p />
            <TermsOfUse />
        </>
    )

}
export default PrivacyPolicy;
