import React from 'react';
import { Box, Typography } from '@material-ui/core';

const TermsOfUse = (props) => {

    return (
        <>
            <Typography component='div' variant="h6" align="center" style={{ flexGrow: 1, fontWeight: 'bold', color: "brown" }}>Online
             Dispute Resolution Portal Terms of Use</Typography>
            <p />
            <Typography variant="body" component='div' align="left" style={{ fontSize: 13 }}><Box fontWeight='fontWeightMedium' display='inline'>Notice </Box></Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>Please read these terms and conditions carefully.
             By accessing this Online Dispute Resolution Portal (as defined below) found on DinersClub.com (“<Box fontWeight='fontWeightMedium' display='inline'>Online Dispute
             Resolution Portal</Box>”) hosted on the Diners Club International Ltd. (“<Box fontWeight='fontWeightMedium' display='inline'>Diners Club</Box>”) digital or mobile
             platform, including but not limited to the mobile application, website and any pages, modules or content found on the Online Dispute Resolution Portal,
             you agree to be bound by the terms and conditions below. If you do not agree to the terms and conditions below, do not access the Diners Club website and/or the
             Online Dispute Resolution Portal, mobile application, or any other Diners Club platform or channel through which Online Dispute Resolution Portal is accessible.
             Diners Club reserves the right to change these terms of use at any time and without notice. We request you to carefully read through these terms of
              use prior to using the Online Dispute Resolution Portal. If you access and continue to browse, and use the Online Dispute Resolution Portal, you irrevocably
              and unconditionally are agreeing to comply with, abide by and be bound by all the obligations as stipulated in these terms of use,
             Diners Club website general terms of use available at DinersClub.com (“<Box fontWeight='fontWeightMedium' display='inline'>General ToU</Box>”),
             and our Online Dispute Resolution Portal specific privacy policy available at DinersClub.com and any other applicable policies referred to herein or made
             available on the Online Dispute Resolution Portal. In case of conflict between these terms of use, i.e., the “<Box fontWeight='fontWeightMedium' display='inline'>
                    Online Dispute Resolution Portal ToU</Box>” and the General ToU, the provisions of Online Dispute Resolution Portal
             ToU shall prevail but only as regard to the Online Dispute Resolution Portal. In all other cases, the General ToU shall prevail over the Online Dispute
             Resolution Portal ToU.
              Further, it shall be your liability and responsibility to comply with the terms and conditions of all such third-party platforms. </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>Only Diners Club card holders to whom the Diners Club cards have been issued by an
            issuing bank in India
            (“<Box fontWeight='fontWeightMedium' display='inline'>India Cardholders</Box>”) are permitted to access this Online Dispute Resolution Portal.
            In case you do not fall under the abovementioned category then you are not permitted to access this Online Dispute Resolution Portal, and you should refrain
            from accessing the Online Dispute Resolution Portal. Copyright © 2022 Diners Club International Ltd. All Rights Reserved </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>Copyright in the pages and in the screens displaying the pages, and in the Information and
            material therein and in their arrangement, is owned by Diners Club International Ltd. (“Diners Club”) unless otherwise indicated. </Typography>
            <p />
            <Typography variant="body" component='div' align="left" style={{ fontSize: 13 }}><Box fontWeight='fontWeightMedium' display='inline'>Trademarks </Box></Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>Belong, Club Cash, Global Vision, Diners, Diners Club, Diners Club International, Diners Club
            International with Split Circle Logo and Split Circle Logo are trademarks and/or service marks of Diners Club and are used and registered throughout the world.
            Diners Club, its parent and affiliate companies may also claim rights in certain other trademarks and service marks contained in these web pages. </Typography>
            <p />
            <Typography variant="body" component='div' align="left" style={{ fontSize: 13 }}><Box fontWeight='fontWeightMedium' display='inline'>Online Dispute Resolution
            Portal </Box></Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>This Online Dispute Resolution Portal has been provided by Diners Club for resolving disputes and
            grievances arising out of failed transactions of any of the India Cardholders in a user-friendly and transparent manner. The scope of this Online Dispute Resolution
            Portal is to redress all disputes and grievances arising out of transaction types mentioned in Reserve Bank of India’s
            circular DPSS.CO.PD No.629/02.01.014/2019-20 dated September 20, 2019, on “Harmonisation of Turn Around Time (TAT) and customer compensation for failed transactions
            using authorised Payment Systems”. </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>You may lodge your complaint and submit necessary Information as requested by Diners Club on this
            Online Dispute Resolution Portal. Please note that, this Online Dispute Resolution Portal is the only Portal made available by Diners Club for resolving disputes and
            grievances arising out of failed transactions of any of the India Cardholders. </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>To lodge a complaint on the Online Dispute Resolution Portal, the Diners Club will first
            authenticate your identity and your Diners Club card, during this process you will receive a one-time password to your registered mobile number from your Issuing
            Bank. You will be required to provide such one-time password, within the Online Dispute Resolution Portal, to initiate the process for lodging and tracking your
            complaint. </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>While lodging a complaint for a failed payment transaction you will be required to provide Diners
            Club personal and non-personal data/Information relating to your identity, your Diners Club card, mobile number, email address and transaction related Information
            (“<Box fontWeight='fontWeightMedium' display='inline'>Customer Data</Box>”). To protect the Customer Data from any unauthorized access, use and disclosure,
            we use data security measures that comply with all applicable laws.
             Please read our Online Dispute Resolution Portal privacy policy available at <a href="https://www.dinersclub.com" >DinersClub.com</a>, we have detailed all the
             measures and process we undertake to protect the Customer Data while collecting,
             storing, sharing and process the Customer Data. </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>Once you have lodged a complaint on this Online Dispute Resolution Portal, you will be given a
            Diner’s Club International Reference Number ("<Box fontWeight='fontWeightMedium' display='inline'>Complaint ID</Box>"), which will be unique for your complaint.
            Once the complaint is filed, the issuing bank, who has issued you the Diners Club card, will promptly work to resolve the dispute or grievance. </Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>Please keep the safe and secure as you will require this Complaint ID to track your complaint
            on the Online Dispute Resolution Portal and will be useful in all your future correspondences in relation to your complaint.
            You may track your complaint by accessing this Online Dispute Resolution Portal and by providing the relevant Information including but not limited to the
            Complaint ID when required. </Typography>
            <p />
            <Typography variant="body" component='div' align="left" style={{ fontSize: 13 }}><Box fontWeight='fontWeightMedium' display='inline'>Use of Information and
             Materials </Box></Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>The Information and materials contained in these pages, and the terms, conditions, and
            descriptions that appear, are subject to change at Diners Club’s sole discretion without prior notice. Unauthorized use
            of Diners Club’s website (including the Online Dispute Resolution Portal) and systems including but not limited to unauthorized entry into Diners Club’s systems,
            misuse of passwords, or misuse of any Information posted on a website is strictly prohibited. You may not modify, copy, distribute, transmit,
            display, perform, reproduce, publish, license, frame, create derivative works from, transfer or otherwise use in any way for commercial or public purposes in
            whole or in part any Information, software, products or services obtained from any Diners Club digital or online platform, except for the purposes expressly stated
            herein, without Diners Club’s prior written approval.
            You agree that when you use Diners Club’s digital and online properties (including the Online Dispute Resolution Portal),
            you will remain subject to the terms and conditions of all your existing agreements with us, our affiliates and/or any unaffiliated service providers and your
             mobile service. carrier or provider, and that these Online Dispute Resolution Portal ToU do not amend or supersede any of those agreements. </Typography>
            <p />
            <Typography variant="body" component='div' align="left" style={{ fontSize: 13 }}><Box fontWeight='fontWeightMedium' display='inline'>No Warranty </Box></Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>The Information and materials contained in Online Dispute Resolution Portal, including text,
            graphics, links or other items are provided “as is,” “as available.” Diners Club does not warrant the accuracy, adequacy or completeness of this Information and
            materials and expressly disclaims liability
            for errors or omissions in this Information and materials. No warranty of any kind, implied, expressed or statutory including but not limited to the warranties
            of non-infringement of third-party rights, title, merchantability, fitness for a particular purpose and freedom from computer virus, is given in conjunction with
             the Online Dispute Resolution Portal or the Diners Club's website. </Typography>
            <p />
            <Typography variant="body" component='div' align="left" style={{ fontSize: 13 }}><Box fontWeight='fontWeightMedium' display='inline'>Limitation of Liability
             </Box></Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>In no event will Diners Club be liable for any damages, including without limitation,
            direct or indirect, special incidental or consequential damages, losses or expenses arising in connection with the Online Dispute Resolution Portal or
            use thereof or inability to use by any party, or in connection with any failure of performance,
            error, omission, interruption, defect, delay in operation or transmission, computer virus or line or system failure, even if Diners Club, or representatives
            thereof, are advised of the possibility of such damages, losses or expenses. </Typography>
            <p />
            <Typography variant="body" component='div' align="left" style={{ fontSize: 13 }}><Box fontWeight='fontWeightMedium' display='inline'>Submissions
            </Box></Typography>
            <p />
            <Typography variant="body" component='div' style={{ fontSize: 13 }}>All Information and Customer Data submitted to Online Dispute Resolution Portal shall be
            governed by the provisions of the Online Dispute Resolution Portal privacy platform available at <a href="https://www.dinersclub.com">DinersClub.com</a>. </Typography>
            <p />
            <Typography variant="body" component='div' align="left" style={{ fontSize: 13 }}><Box fontWeight='fontWeightMedium' display='inline'>Governing Law </Box></Typography>
            <p />

            <Typography variant="body" component='div' style={{ fontSize: 13 }}>Use of Diners Club’s digital and online properties shall be governed by all applicable Federal laws
             of the United States of America and the laws of the State of New York. </Typography>
        </>
    )
}
export default TermsOfUse;
