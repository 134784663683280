import { createStore } from 'redux'

const initData = {
    sessionId: '',
    isAuthenticated: false
}

const reducer = (state = initData, { type, payload } = {}) => {

    if (type === 'ADD_SESSION') {
        return {
            ...state,
            sessionId: payload
        }
    }
    if (type === 'REMOVE_SESSION') {
        return {
            ...state,
            sessionId: ''
        }
    }
    if (type === 'AUTHENTICATE') {
        return {
            ...state,
            isAuthenticated: payload
        }
    }


    return state;
}

const store = createStore(reducer);

export default store;
